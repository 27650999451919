.quote-container {
  position: relative;

  .quote-image {
    background-size: cover;
    min-height: 300px;
    max-height: 300px;
    height: 90vh;
  }

  .quote-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: white;
    width: 75vw;
    * {
      text-align: center;
      font-weight: 900;
    }
  }
  .quote-cta {
    margin-top: 15px;
  }
}