.heading h5 {
  font-weight: 600;
}

.section-container {
  padding: 3rem 0px;
}

.circle-image-container {
  position: relative;
  height: auto;
  width: 50%;
  padding-top: 50%;
  border-radius: 50%;
  margin: auto;

  @include breakpoint(small only) {
    width: 35%;
    padding-top: 35%;
  }
}
.truckers-btn {
  font-size: 2em;
  font-weight: 900;
  padding: 2px 45px;
  background-color: $primary-color;
  display: inline-block;
  border-radius: 50px;

  @include breakpoint(small only) {
    font-size: 1.5em;
  }
  
  &:hover {
    background-color: darken($primary-color, 10%);
    cursor: pointer;
  }
  a {
    color: white;
  }
}

.text-italic {
  font-style: italic;
  font-weight: 500;
}

.bottom-hr {
  background-color: $success-color;
  margin-top: 20px;
  width: 80px;
  height: 4px;
  margin-left: auto;
  margin-right: auto;
}