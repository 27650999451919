.benefits-container {
  background-color: $primary-color;
  .heading h5 {
    color: white;
    font-size: 2em;
    margin-bottom: 3rem;
  }

  .benefits-image-container {
    text-align: center;
    background: white; /* For browsers that do not support gradients */
    padding: 0;
  }

  .benefits-text {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    h6 {
      color: white;
      font-weight: 900;
      font-size: 1.5em;
    }
    p {
      color: white;
    }
  }

  &.inverted {
    background-color: white;
    .heading h5 {
      color: $success-color;
    }
    .benefits-text {
      h6 {
        color: $success-color;
      }
      p {
        color: $success-color;
        line-height: 1.3;
      }
    }


  }

}