.services-container {
  .heading h5 {
    color: $primary-color;
    font-size: 2em;
    margin-bottom: 3rem;
  }

  .services-image-container {
    text-align: center;
    padding: 0;
  }

  .services-text {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    h6 {
      color: $primary-color;
      font-weight: 900;
      font-size: 1.5em;
    }
  }


}