.footer-container {
  background-color: #1a1a1a;
  color: white;
  padding: 5rem 0px 0px 0px;

  .heading {
    margin-bottom: 25px;
  }
  .location-container {
    margin-top: 35px;
    p {
      font-size: 14px;
      margin: 0;
    }
  }
  .contact-container {
    padding-bottom: 25px;
    p {
      margin: 0;
    }
    .contact-borders {
      border-left: 1px solid white;
      border-right: 1px solid white;
      @include breakpoint(small only) {
        border: none;
      }
    }
  }
}