.header-container {
  -webkit-box-shadow: 0px 5px 21px 0px rgba(0,0,0,0.57);
  -moz-box-shadow: 0px 5px 21px 0px rgba(0,0,0,0.57);
  box-shadow: 0px 5px 21px 0px rgba(0,0,0,0.57);
  position: fixed;
  top: 0;
  display: block;
  width: 100vw;
  z-index: 100;

  .title-bar {
    position: relative;
  }
  
  .menu-icon {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
  }

  .title-bar {
    background-color: white;
  }

  .top-bar-right {
    @include breakpoint(medium up) {
      padding-top: 20px;
    }
  }
  
  .no-js {
    @include breakpoint(small only) {
      .top-bar {
        display: none;
      }
    }

    @include breakpoint(medium) {
      .title-bar {
        display: none;
      }
    }
  }

  .menu li {
    border-bottom: 3px solid transparent;
  }

  .menu li.active{
    border-bottom: 3px solid $primary-color;
    
    a {
      background: transparent;
      color: darken($primary-color, 20%);
    }
  }

  .menu li:hover {
    border-bottom: 3px solid $primary-color;
  }

}