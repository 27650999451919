.process-container {
  .heading h5 {
    color: $success-color;
    font-size: 2em;
    margin-bottom: 3rem;
  }
}

.process-step-container {
  margin-bottom: 5rem;
  position: relative;
  @include breakpoint(medium down) {
    margin-bottom: 3rem;
  }

  @include breakpoint(medium up) {
    .process-text-container {
      position: relative;
    }

    .text-container {
      top: 35%;
      transform: translateY(-35%);
      position: absolute;
    }
  }
  .step-heading-container {
    position: relative;
    .circle {
      text-align: center;
      position: relative;
      height: auto;
      width: 65px;
      padding-top: 65px;
      border-radius: 100%;
      display: inline-block;

      background: $success-color; /* For browsers that do not support gradients */
      background: -webkit-linear-gradient(left, #0971B2, #122A4D); /* For Safari 5.1 to 6.0 */
      background: -o-linear-gradient(right, #0971B2, #122A4D); /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(right, #0971B2, #122A4D); /* For Firefox 3.6 to 15 */
      background: linear-gradient(to right, #0971B2, #122A4D); /* Standard syntax */
    }
    .circle-text {
      position: absolute;
      left: 20px;
      top: 5px;
      h2 {
        color: white;
        font-weight: 900;
        margin: 0;
        @include breakpoint(medium only) {
          font-size: 26px;
        }
      }
    }

    .title {
      display: inline-block;
      vertical-align: bottom;
      margin-left: 15px;
      font-weight: 900;
      color: $success-color;
      position: relative;
      
      @include breakpoint(large up) {
        top: -10px;
      }
    }
    @include breakpoint(medium only) {
      .circle {
        width: 35px;
        padding-top: 35px;
      }
      .circle-text {
        left: 10px;
        top: 0px;
      }
      .title {
        margin-left: 5px;
      }
    }
    @include breakpoint(small only) {
      .circle {
        width: 25px;
        padding-top: 25px;
      }
      .circle-text {
        left: 7px;
        top: 0px;
      }
      .title {
        margin-left: 5px;
      }
    }

  }

  .background-image {
    text-align: center;
  }

  .background-gradiant {
    padding-top: 35%;
    background: $success-color; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, #0971B2, #122A4D); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, #0971B2, #122A4D); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, #0971B2, #122A4D); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, #0971B2, #122A4D); /* Standard syntax */
  }

  .background-gradiant.flipped {
    padding-top: 35%;
    background: $success-color; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(right, #0971B2, #122A4D); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(left, #0971B2, #122A4D); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(left, #0971B2, #122A4D); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to left, #0971B2, #122A4D); /* Standard syntax */
  }
}