.contact-form-outer {
  @include breakpoint(large up) {
    position: relative;
    min-height: 800px;
  }
}


.contact-success-container {
  position: relative;
  padding-bottom: 50px;
  padding-top: 35px;
  @include breakpoint(medium up) {
    padding-top: 15vh;
    padding-bottom: 10vh;
  }

  h1 {
    color: $primary-color;
    text-align: center;
    font-weight: 300;
    padding-bottom: 45px;
    font-size: 75px;
    line-height: 1.2;
    @include breakpoint(medium down) {
      font-size: 45px;
    }
  }
  h2 {
    color: $primary-color;
    text-align: center;
    font-weight: 600;
    @include breakpoint(medium down) {
      font-size: 35px;
    }
  }
  * {
    color: $primary-color;
  }
  &.hidden {
    display: none !important;
  }
}

.contact-form-container {
  position: relative;
  padding-bottom: 30px;
  padding-top: 35px;
  &.hidden {
    display: none !important;
  }

  @include breakpoint(medium up) {
    padding-top: 10vh;
  }

  h1 {
    color: $primary-color;
    text-align: center;
    font-weight: 600;
    padding-bottom: 25px;
  }
  * {
    color: $primary-color;
  }
  .truckers-btn {
    margin: auto;
    margin-top: 15px;
    a {
      color: white;
    }
  }
}
.contact-img-container {
  position: relative;
  .bg-image {
    width: 100%;
    height: 90vh;
    background-image: url("/assets/images/sidebar.jpg");
    background-size: cover;
    @include breakpoint(large up) {
      position: relative;
      min-height: 800px;
    }
    @include breakpoint(medium down) {
      height: 40vh;
      background-position: center 100%;
    }
  }
  .text {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%) translateY(-30%);
    color: white;
    width: 70%;
    h3 {
      font-size: 25px;
    }
    @include breakpoint(medium down) {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 80%;
      h3 {
        font-size: 20px;
      }
    }
  }

}