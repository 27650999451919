.hero-container {
  position: relative;

  .hero-image {
    background-size: cover;
    min-height: 300px;
    max-height: 500px;
    height: 90vh;
  }

  .hero-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5vw;
    color: white;
    max-width: 70vw;
    h1 {
      font-weight: 900;
      line-height: 1.2;
    }
  }
  .hero-cta {
    margin-top: 15px;
  }

  &.short {
    .hero-image {
      min-height: 200px;
      max-height: 400px;
      height: 90vh;
      background-position: center;
      @include breakpoint(small only) {
        min-height: 200px;
        max-height: 200px;
        height: 90vh;
      }
    } 
    .hero-text {
      position: absolute;
      bottom: 10%;
      top: initial;
      transform: initial;
      left: 5vw;
      color: white;
      max-width: 70vw;
      -webkit-text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
      -moz-text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
      text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
      
      h1 {
        font-weight: 900;
        line-height: 1.2;
        font-size: 100px;
        @include breakpoint(medium down) {
          font-size: 50px;
        }
      }
    }
  }
}